import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { AxiosRequestor } from '../../services/AxiosService';
import AxiosConfig from '../../services/AxiosConfig';
// import { notifyListeners } from 'mobx/dist/internal';
import Axios, { CancelTokenSource } from 'axios';
import Moment from 'react-moment';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import fileDownload from 'js-file-download';
import { isPlatform } from '@ionic/react';

const classNames = require("classnames");

interface PrevAndNext {
    [key: string]: any;
}

interface NoticeItem {
    [key: string]: any;
}

const NoticeView = (props:any) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [notice, setNotice] = useState<NoticeItem>();
    const [noticeFile, setNoticeFile] = useState<NoticeItem>([]);
    const [prevAndNext, setPrevAndNext] = useState<PrevAndNext>();

    // FILE_NOTICE_CODE
    // case 1 : //배너팝업
    //     return repository.findByBannerPopupUid(uid);
    // case 2 : // 관광지
    //     return repository.findByContentsUid(uid);
    // case 3 : //상품
    //     return repository.findByProductUid(uid);
    // case 4 : //공지사항

    const fetchNoticeFile = async (fileGroupUid:number) => {
        let url = `/api2/commonfileInfo/${fileGroupUid}?code=4`;
        const res = await AxiosConfig.get(url);
        setNoticeFile(res.data);
    }

    const fetchNoticeInfo = async () => {
        let url = `/api2/siteNoticeInfo/${props.match.params['uid']}`;
        const res = await AxiosConfig.get(url);
        setNotice(res.data);
        console.log(res.data);
        if (res.data.fileGroupUid != null && (typeof res.data.fileGroupUid) == "number") {
            fetchNoticeFile(res.data.fileGroupUid);
        }
    }

    const fetchPrevAndNextNoticeInfo = async () => {
        
        let url = `/api2/prevAndNextNoticeInfo?uid=${props.match.params['uid']}`;
        console.log(url);
        const res = await AxiosConfig.get(url);
        setPrevAndNext(res.data);
        console.log(res.data);
    };

    
    useEffect(() => {
        
        fetchNoticeInfo();
        fetchPrevAndNextNoticeInfo();
    }, [props.match.params['uid'], globalInfoStore.currentLangType]);

    const downloadFile = (fileUrl:string) => {
        window.open(fileUrl, "_blank")
    }

    const handleDownload = (url:string, filename:string) => {
    //   let lastIndex = url.lastIndexOf("/");
    //   let filename = url.substring(lastIndex + 1, url.length);
        console.info("handleDownload::url" + url);
        Axios.get(url, {
            responseType: 'blob',
        })
        .then((res) => {
            console.info("handleDownload::res");
            fileDownload(res.data, filename)
        })
        .catch((e)=>{
            console.error("handleDownload::error::" + e);
        })
        .finally(()=>{
            console.log("handleDownload::finally");
        })

    }
    
    const goToNotice = () => {
        history.push("/app/cs/index/notice");
    }

    const NoticeBody = () => {
        if (notice == undefined || notice.uid == null)
            return (<></>);
        return (
            <Fragment>
                <div className="titArea">
                    {/* <span className={classNames({sort: true, event: (notice.category == 2)})} > {t("notice_view.notice_short")}</span> */}
                    <span className={classNames({sort: true, event: (notice.category == 2)})} > {(notice.category == 2)?t("notice.label_event"):t("notice.label_notice")}</span>
                    <span className="tit">{notice[globalInfoStore.currentLangType.title]}</span>
                    <span className="date">
                        <Moment format="YYYY.MM.DD">
                            {notice.updateDate}
                        </Moment>
                    </span>
                </div>
                <div className="contArea"
                    dangerouslySetInnerHTML={{__html: notice[globalInfoStore.currentLangType.desc]}} 
                    >
                </div>

                {noticeFile.length > 0?
                <div className="fileArea">
                    {noticeFile.map((it:any) => {return (
                        <>
                            {/* click 또는 handleDownload 사용시 ios app 에서 정상 다운로드 안됨. */}


                            {(!(isPlatform("capacitor") && isPlatform("android")) && !(isPlatform("capacitor") && isPlatform("ios")))?null
                            :
                            <div className="attachfile">
                                <a href={it.fullpath} download={it.filename} target="_blank">{it.filename}</a><br/>
                            </div>
                            }

                            {((isPlatform("capacitor") && isPlatform("android")) || (isPlatform("capacitor") && isPlatform("ios")))?null
                            :
                            <div className="attachfile">
                                <a onClick={(e)=>handleDownload(it.fullpath, it.filename)} style={{cursor: "pointer"}} >{it.filename}</a><br/>
                            </div>
                            }

                            {/* <a onClick={(e)=>handleDownload(it.fullpath, it.filename)} style={{cursor: "pointer"}} >{it.filename}</a><br/>  */}

                            {/* <a href={it.fullpath} download={it.filename} target="_blank">{it.filename}</a><br/> */}
                        </>
                    )
                    })}
                </div>
                :""
                }
            </Fragment>
        );
    };
    
    const Prev = () => {
        if (prevAndNext == undefined || prevAndNext.prevUid == null)
            return (<></>);
        return (
            <li className="prev">
                <span>{t("notice_view.prev")}</span>
                <Link to={`/app/cs/index/notice/view/${prevAndNext.prevUid}`} >{prevAndNext['prev' + globalInfoStore.currentLangType.title]}</Link>
            </li>
        )
    };

    const Next = () => {
        if (prevAndNext == undefined || prevAndNext.nextUid == null)
            return (<></>);
        return (
            <li className="next">
                <span>{t("notice_view.next")}</span>
                <Link to={`/app/cs/index/notice/view/${prevAndNext.nextUid}`} >{prevAndNext['next' + globalInfoStore.currentLangType.title]}</Link>
            </li>
        )
    };
    
    return (
        // <h6 className="hidden">공지사항 내용</h6>
        <div className="layout">
            
            <div className="board_view">
                <NoticeBody></NoticeBody>

            </div>

            <div className="wrap_btn view">
                <button onClick={goToNotice}>{t("notice_view.list")}</button>
            </div>

            <ul className="board_view_pager">
                <Next></Next>
                <Prev></Prev>
            </ul>

        </div>
    );
};

export default NoticeView;