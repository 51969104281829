import React from "react";
import { useTranslation } from "react-i18next";
import AppDownload from "../../components/organisms/AppDownload";
import { isPlatform } from "@ionic/react";
import { useLocation } from "react-router-dom";

import cycle01 from '../../resources/img/cycle01.png';
import cycle02 from '../../resources/img/cycle02.png';

const TransportationAirportBus = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_airportbus.title")}</p>
            <div className="exp_box" dangerouslySetInnerHTML={{__html:t("how_airportbus.desc")}}></div>
            <a href={t("how_airportbus.link_url")} target="_blank" className="btn">{t("how_airportbus.link")}</a>

            <div className="step row04 bus">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_airportbus.step1_cont")}}></dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_airportbus.step2_cont")}}></dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_airportbus.step3_cont")}}></dd>
                </dl>
                <dl className="step04">
                    <dt>STEP 4</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_airportbus.step4_cont")}}></dd>
                </dl>
            </div>

            {isPlatform("capacitor") || location.pathname.startsWith('/appnv') ? null
            :
                <div className="exp_box use03">
                    <span>{t("how_arex.app_download")}</span>
                    <AppDownload type="bold"></AppDownload>
                </div>
             }

        </div>
    );
};

export default TransportationAirportBus;