import { Plugins, StatusBarStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import AOS from 'aos';
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import ScrollToTop from "./components/ScrollToTop";
import GlobalCss from './GlobalCss';
import AppMyPass from './pages/app-mypass/AppMyPass';
import AppMyPassEmpty from './pages/app-mypass/AppMyPassEmpty';
import AppMyPassRegister from './pages/app-mypass/AppMyPassRegister';
import NotFound from './pages/error/NotFound';
import Layout from './pages/Layout';
import LayoutNV from './pages/LayoutNV';
import { PrivateRoute } from './routes/PrivateRoute';
import AxiosConfig from './services/AxiosConfig';
import { ProductInfoService } from './services/ProductInfoService';
import { globalInfoStore, ILanguage, ISiteLanguageInfo, LANG_TYPES } from './stores/GlobalInfoStore';
import ReactGA from 'react-ga';
import { useHistory } from "react-router";

import './resources/css/app.css';
import './resources/css/common.css';
/* Discover Seoul Pass */
// import './resources/css/koEn.css';
import './resources/css/layout.css';
import './resources/css/slick-theme.css';
import './resources/css/slick.css';
import './resources/css/sub.css';
import ErrorBoundary from './components/ErrorBoundary';
import { stringify } from 'querystring';

import GSurvey from './pages/mypage/survey/GSurvey';


const { StatusBar } = Plugins;

AOS.init();
window.addEventListener('load', AOS.refresh);
// const channelService = new ChannelService();
// export const channelService = new ChannelService();

var isGSurvey = false;
var isGSurveyDspJwtUpdated = false;


const App: React.FC = () => {
    console.log('I render 😁');
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [siteLanguageInfo, setSiteLanguageInfo] = useState<ISiteLanguageInfo[]>([]);
    const [ isCookiePopupShowed, setCookiePopupShowed ] = useState(false);
    const fetchSiteLanguageInfo = async (langType:ILanguage) => {
      const url = `/api2/siteLanguageInfo`;
      const res = await AxiosConfig.get(url);
      if (res.status === 200) {
        setSiteLanguageInfo(res.data);

        fetchGSurveyInfo();
      }
    };

    const fetchGSurveyInfo = async () => {
      if (globalInfoStore.getDspJwt() != null) {
        var mobileos = 0;
        if (isPlatform("capacitor") && isPlatform("ios")) {
          mobileos = 1;
        } else if (isPlatform("capacitor") && isPlatform("android")) {
          mobileos = 2;
        }

        const data = JSON.stringify({
          fcm_token: localStorage.getItem("deviceToken"),
          mobileos: mobileos
        });
        const res = await AxiosConfig.post(`/api2/isGSurvey`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.status === 200) {
          if(res.data.isGSurvey == 1) {
            history.push('/gsurvey');
          }
        } 
      }
    };

    document.removeEventListener('gsurveyRegistered',  (event:any) => {});
    document.addEventListener('gsurveyRegistered',  (event:any) => {
      const data = event.detail;
      localStorage.setItem("deviceToken", data);
    });

    document.removeEventListener('gsurveyReceived',  () => {});
    document.addEventListener('gsurveyReceived', () => {
      if(isGSurvey) {} else {
        isGSurvey = true;
        fetchGSurveyInfo();
      }
    });

    document.removeEventListener('dspJwtUpdated',  () => {});
    document.addEventListener('dspJwtUpdated', () => {
      if(isGSurveyDspJwtUpdated) {} else {
        isGSurveyDspJwtUpdated = true;
        fetchGSurveyInfo();
      }
    });

    document.removeEventListener('surveyPageShowed',  () => {});
    document.addEventListener('surveyPageShowed', () => {
      isGSurveyDspJwtUpdated = false;
    });


    useEffect(() => {
      //  console.log("setSiteLanguageActive:: 1")
       if (siteLanguageInfo === undefined || siteLanguageInfo.length < 1) {
          //console.log("setSiteLanguageActive:: 2")
          return;
        }
        // console.log("setSiteLanguageActive:: 3 globalInfoStore.currentLangType.i18str:: " + globalInfoStore.currentLangType.i18Str)
        // lang이 비활성화 상태일경우 영어로 기본설정.
        if (siteLanguageInfo[globalInfoStore.currentLangType.langCode - 1].status === 2) {
          // console.log("setSiteLanguageActive:: 4")
          globalInfoStore.handleSetLang(LANG_TYPES[1]); //영어로 설정
        }
        
    }, [siteLanguageInfo]);

    useEffect(() => {
      setCookiePopupShowed(localStorage.isCookiePopupShowed);
      
       let langType = LANG_TYPES.find((it) => it.i18Str == i18n.language);
       
       if(localStorage.isCookieUse === 'false') {
         langType = null;
       }

       if (langType == null) {
        langType = LANG_TYPES[1];
       }
       globalInfoStore.handleSetLang(langType);
       fetchSiteLanguageInfo(langType);
    }, []);

    useEffect(() => {
    }, [globalInfoStore.currentLangType]);   

    globalInfoStore.expireDspJwt()

    if (isPlatform("capacitor") && isPlatform("android")) {
      StatusBar.setStyle({
        style: StatusBarStyle.Dark
      });
    } else if (isPlatform("capacitor") && isPlatform("ios")) {
      StatusBar.setStyle({
        style: StatusBarStyle.Light
      });
      
    }

    ReactGA.initialize("UA-80176637-1");
    
    //INIT Service Instance.
    ProductInfoService.Instance()
    // StatusBar.setBackgroundColor({
    //   color: "#FF0000"
    // })
    // StatusBar.setOverlaysWebView({
    //   overlay: false
    // });

    const cookieAccept = () => {
      localStorage.isCookiePopupShowed = true;
      localStorage.isCookieUse = true;
      setCookiePopupShowed(true);
    }

    const cookieReject = () => {
      localStorage.isCookiePopupShowed = false;
      localStorage.isCookieUse = false;
      setCookiePopupShowed(true);
    }

     /**
     * 쿠키 팝업
     * @param props 
     * @returns 
     */
     const CookiePopup = () => {
      return (
        <>
        <div className="cookie-popup-area" id='cookiePopup'>
          <div className="inner-cont">
            <div className="text-cont" dangerouslySetInnerHTML={{__html:t("main.cookie_content")}}>
            </div>
            <div className="btn-block">
                <a href="javascript:void(0);" className="btn-primary">
                  <button className="btn-primary" onClick={(e)=>cookieAccept()}><em>{t("main.cookie_acept")}</em></button>
                </a>
                <a href="javascript:void(0);">
                  <button className="btn-primary" onClick={(e)=>cookieReject()}><em>{t("main.cookie_reject")}</em></button>
              </a>
              <a href="https://www.sto.or.kr/english/privacy" target="_blank" title="open new window">
                <em>{t("main.cookie_info")}</em>
              </a>
            </div>
          </div>
        </div>  
        </>
      )
    }

    return (
        <React.StrictMode>
          <RecoilRoot>
              {/* <ErrorBoundary> */}

                <React.Suspense fallback={<div>Loading...</div>}>
                <GlobalCss></GlobalCss>
                <Helmet>
                  <html lang={globalInfoStore.currentLangType.htmlLang} />
                </Helmet>
                <ScrollToTop />

                  {/* <Header></Header> */}
                <Switch>
                  <PrivateRoute exact path="/appmypass" component={AppMyPass} />
                  <PrivateRoute exact path="/appmypass/empty" component={AppMyPassEmpty} />
                  <PrivateRoute exact path="/appmypass/register" component={AppMyPassRegister} />
                  <PrivateRoute exact path="/gsurvey" component={GSurvey} />

                  {/* <Route exact path="/" component={Layout} /> */}
                  <Route exact path="/" render={() => <Redirect to="/app" />} />
                  <Route path="/app" component={Layout} />
                  <Route path="/appnv" component={LayoutNV} />
                  <Route component={NotFound} />
                </Switch>
                </React.Suspense>
                
                {(isCookiePopupShowed ? <></> : <CookiePopup></CookiePopup>)}
              
          </RecoilRoot>
        </React.StrictMode>
  
    );
};

export default App;
