import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import AxiosConfig from '../../services/AxiosConfig';
import { getValueByKey } from "../../services/Util";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { IOrderEasypayRequest, OrderEasypayRequest } from "./IEasypayWeb";
import { CP_SUBCODE_PERCENT, CP_SUBCODE_PRICE } from "./PassView";

const classNames = require("classnames");

export interface IOrderInfo {
   
    uid:number;  //- * 주문의 고유번호
    status:number;  //- * 주문 상태( 1. 주문접수, 2. 수령완료, 5. 환불요청, 6. 환불완료, 7. 폐기 )
    userName:string;  //- * 구매 회원 이름(가입되어 있지 않는 회원의 이름을 받을때 사용 <Ex: 예약>)
    userUid:number;  //- * 구매 회원 고유번호
    productUid:number;  //- * 상품 고유번호
    couponUid:number;  //- * 쿠폰 고유번호
    sellerUid:number;  //- * 판매처 고유번호
    receiverUid:number;  //- * 수령처의 고유번호
    amount:number;  //- * 주문 수량
    finalPrice:number;  //- * 최종 결제액(원화)
    passtypePrice:number;  //- * 판매당시 상품의 권종 정가
    salePrice:number;  //- * 판매당시 상품의 개당 판매가(원화)
    commissionPrice:number;  //- * 판매당시 판매처의 해당 상품 판매 수수료
    discountPrice:number;  //- * 판매당시 쿠폰으로 할인 된 금액
    referenceCode:string;  //- * 주문번호 또는 예약번호(엑심베이 등등)
    paymentMethod:string;  //- * 결제 방식(엑심베이 리턴 메소드 타입)
    createDate:Date;  //- * 주문 생성일자
    updateDate:Date;  //- * 주문 수정일자
    purchaseDate:Date;  //- * 구매일자
    receiptDate:Date;  //- * 수령일자
    refundDate:Date;  //- * 환불일자
    finalRefundDate:Date;  //- * 최종 환불일자
    storePartnerUid:number;  //- * 판매 제휴시설 고유번호
    receiverPartnerUid:number;  //- * 수령처 제휴시설 고유번호
    code:number;   //- * passtypeCode
    passtypeUid:number;
    passtypeTime:string;  // 24,48,72
    managingTitle:string;
    koTitle:string;
    enTitle:string;
    jaTitle:string;
    zhCnTitle:string;
    zhTwTitle:string;
    expectedReceiptDate:Date;
    expectedReceiveUid:number;
    filename:string;
    filepath:string;
    fullpath:string;
    extension:string;
    visitCount:string;
    iccid_num:string;   //이심구매usim일련번호
}

interface PassFinishPageProps extends RouteComponentProps {
    // orderResult:
}

interface IAny {
    [key: string]: any;
}

const PassFinish = (props: any) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    // const [orderResult, setOrderResult] = useState({})
    const [filteredOrderResult, setFilteredOrderResult] = useState<IOrderInfo[]>([])
    let orderProcessResult = null;
    let orderEasypayRequest:any = null; //* OrderEasypayRequest | OrderEasypayMobileRequest --> // FIXME: PC웹과 모바일웹의 분리로 인해 type 특정이 안됨. 추후 수정 필요.
    try {
        if (props.location.state != null && props.location.state.orderEasypayRequest != null) {
            orderEasypayRequest = props.location.state.orderEasypayRequest;
        }
        if (props.location.state != null && props.location.state.orderProcessResult != null) {
            orderProcessResult = props.location.state.orderProcessResult;
        }
    } catch (err) {
        console.error(err);
    }

    //*    path="/app/pass/finish/:productUid/:orderNo" component={PassFinish} />

    const fetchOrder = async () => {
        // page:number = 0, size:number = 1000, sorts:[]
        const page:number = 0, size:number = 1000, sorts:[] = [];
        let url = `/api2/orderInfo?page=${page}&size=${size}`;
        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }
        const res = await AxiosConfig.get(url);
        // setOrderResult(res.data);
        // setFilteredOrderResult(res.data.content.filter((it:any) => it.orderNo == props.match.params['orderNo']))
        setFilteredOrderResult(res.data.content.filter((it:any) => {
            return it.referenceCode == props.match.params['orderNo']}
        ))
        console.info(JSON.stringify(filteredOrderResult));
        // console.log(res.data);
    };


    useEffect(() => {
        fetchOrder()
        return () => {
        }
    }, [])

    /**
     * 
     * @param passtypeCode IOrderInfo.code
     * @returns 
     */
    const getPasstypeName = (passtypeCode:number) => {
        if (passtypeCode === 1) {
            return t("mypass.card_pass")
        } else {
            return t("mypass.mobile_pass")
        }
    }

    const goToOrderHistory = () => {
        history.replace("/app/mypage/orderhistory");
    }

    // if (filteredOrderResult == null || filteredOrderResult.length < 1) {
    //     return (
    //         <>
    //             <p>잘못된 주문 정보입니다</p>
    //         </>
    //     )
    // }


    if (filteredOrderResult.length < 1 ) {
        return (<></>)
    }

    if (orderProcessResult == null || orderEasypayRequest == null) {
        return (<>주문정보가 올바르지 않습니다.</>)
    }

    const TotalDiscountPrice = () => {

        if (orderEasypayRequest.couponSubCode == CP_SUBCODE_PERCENT){
            const discountPrice = ((((orderEasypayRequest.unitOfSalesPrice) * (orderEasypayRequest.unitOfCouponPrice * 0.01)) * orderEasypayRequest.quantity) || 0).toLocaleString();
            return (<>
                <p>{orderEasypayRequest.couponName} / {orderEasypayRequest.unitOfCouponPrice} %</p>
                <p className="cost">KRW {discountPrice}</p>
            </>);
        } else if (orderEasypayRequest.couponSubCode == CP_SUBCODE_PRICE){
            const discountPrice = ((orderEasypayRequest.unitOfCouponPrice * orderEasypayRequest.quantity) || 0).toLocaleString();
            return (<>
                <p>{orderEasypayRequest.couponName} / {discountPrice} X {orderEasypayRequest.quantity} 원 할인</p>
                <p className="cost">KRW {discountPrice}</p>
            </>)
        }

        return (<></>);
    }
    
    return (
        <div className="layout">

            <div className="wrap_passVoucher">
{/* {props.location.state.result */}
                {/* <p className="title">productUid: {props.match.params['productUid']} 구매완료</p> */}
                <p className="title">{t("pass_finish.title")}</p>
                {/* <p className="exp" >마이페이지에서 구매내역 및 바우처 정보를 확인할 수 있습니다.<br />
                모바일 패스의 경우, 모바일 APP을 다운로드하시면 바로 사용하실 수 있습니다.</p> */}
                <p className="exp" dangerouslySetInnerHTML={{__html:t("pass_finish.desc")}}></p>

                <div className="voucher">
                    <p className="header">{t("pass_finish.order_information")}</p>
                    <dl>
                        <dt className="th">{t("pass_finish.order_no")}</dt>
                        <dd className="td">
                            <p className="number">{props.match.params['orderNo']}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className="th">{t("pass_finish.purchase_date")}</dt>
                        <dd className="td">
                            {/* <p>{props.location.state.result.tran_date}</p> */}
                            <p>{orderEasypayRequest.tran_date}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className="th">{t("pass_finish.product")}</dt>
                        <dd className="td">
                            <p>
                            
                                <span className={classNames({banner:true, card:true})}>{getPasstypeName(filteredOrderResult[0].code)}</span>

                                <span className={classNames({banner:true, 
                                    time24:(filteredOrderResult[0].passtypeTime=="24"),
                                    time48:(filteredOrderResult[0].passtypeTime=="48"),
                                    time72:(filteredOrderResult[0].passtypeTime=="72")})} >
                                        {filteredOrderResult[0].passtypeTime}H
                                </span>

                                <span className="tit">
                                {/* filteredOrderResult.lenght {filteredOrderResult.length}
                                filteredOrderResult[0].koTitle {filteredOrderResult[0].koTitle}
                                디스커버서울패스 플러스에디션 24시간권 */}
                                {getValueByKey(filteredOrderResult[0],globalInfoStore.currentLangType.title)}
                                {/* {filteredOrderResult[0][globalInfoStore.currentLangType.title]} */}
                                </span>
                            </p>
                            <p className="cost">
                                {/* 2X KRW 79,800 */}
                                {orderEasypayRequest.quantity}X KRW {orderEasypayRequest.unitOfSalesPrice.toLocaleString()}
                            </p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className="th">{t("pass_finish.discount")}</dt>
                        <dd className="td">
                            
                            <TotalDiscountPrice></TotalDiscountPrice>
                            
                        </dd>
                    </dl>
                    <dl className="total">
                        <dt className="th">{t("pass_finish.total")}</dt>
                        <dd className="td">
                            <p className="cost">KRW {orderEasypayRequest.sumOfFinalPrice.toLocaleString()}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className="th">{t("pass_finish.payment_method")}</dt>
                        <dd className="td">
                            <p>
                                {/* CREDIT CARD */}
                                {/* {orderEasypayRequest.paymentMethod} */}
                                {orderEasypayRequest.paymentMethod==11?"CREDIT CARD": ""}
                            </p>
                        </dd>
                    </dl>
                </div>

                <button onClick={goToOrderHistory}>{t("pass_finish.my_orders")}</button>
            </div>

            </div>
    );
};

export default PassFinish;